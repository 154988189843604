import React from 'react'
import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import iconSpeechBubbleGreen from '../../assets/images/icon-speech-bubble-green.svg'

const BannerLogo = () => (
  <img
    className="banner-logo"
    src="/img/logo-white.png"
    alt="Pronto Broadband"
  />
)

const StartHereButton = ({ variation = 1, label, url }) => (
  <button className="btn-banner" onClick={() => (window.location.href = url)}>
    {label}
  </button>
)

export const HeroBanner = ({ variation = 1 }) => (
  <>
    <section className={`banner banner-${variation}`}>
      {variation === 1 && (
        <>
          <div className="section-image"></div>
          <div className="section-content">
            <div className="section-item">
              <BannerLogo />
              <p className="tagline">
                Connect your world with
                <br />
                Broadband that never sleeps!
              </p>
            </div>
          </div>
        </>
      )}
      {variation === 2 && (
        <div className="section-content">
          <div className="section-item">
            <BannerLogo />
            <p className="tagline">
              A great range of nbn™ broadband plans to help you stay connected
            </p>
          </div>
          <div className="section-item">
            <img
              src={iconSpeechBubbleGreen}
              alt="icon speech bubbles"
              width="50%"
              className="banner-icon"
            />
          </div>
        </div>
      )}
      {variation === 4 && (
        <>
          <div className="section-image"></div>
          <div className="section-content">
            <div className="section-item">
              <BannerLogo />
              <p className="tagline">
                SIM only mobile plans starting from $15 per month
              </p>
              <ScrollLink
                tabIndex=""
                to="plans"
                smooth={true}
                duration={600}
                className="btn-banner"
              >
                Choose plan
              </ScrollLink>
            </div>
          </div>
        </>
      )}
    </section>
    {variation === 4 && (
      <section className="sim-activation-call-to-action">
        <h2>Got your Pronto Broadband SIM and you're ready to go?</h2>
        <Link className="btn btn-lg btn-accord btn-orange" to="/sim-activation">
          Activate my SIM
        </Link>
      </section>
    )}
  </>
)

HeroBanner.propTypes = {
  variation: PropTypes.number,
}
