import React from 'react'
import PropTypes from 'prop-types'

import trophy from '../../assets/images/showcase-trophy.svg'
import rocket from '../../assets/images/showcase-rocket.svg'
import danger from '../../assets/images/critical-danger.png'
import transferYourExistingNumber from '../../assets/images/transfer-your-existing-number.svg'
import support from '../../assets/images/support-365-days-a-year.svg'
import network from '../../assets/images/high-speed-network.svg'
import noLockInContract from '../../assets/images/no-lock-in-contract.svg'

export const Showcase = ({ variation = 1 }) => (
  <section className="showcase showcase-why-accord">
    <div className="container">
      {variation === 2 ? (
        <h2 className="banner-title">Why Pronto Broadband?</h2>
      ) : (
        <h1 className="banner-title">
          Why Pronto Broadband {{ 3: 'Mobile', 1: 'nbn™' }[variation]}?
        </h1>
      )}
    </div>
    <div className="showcase-container">
      <div className="row">
        <div className="col-md-3">
          {
            {
              1: (
                <div className="trophy showcase-item">
                  <img src={trophy} alt="icon trophy" />
                  <h2>High-performance speeds</h2>
                  <p>
                    Experience lightning-fast internet speeds, empowering
                    seamless connectivity for a fast-paced digital world.
                  </p>
                </div>
              ),
              2: (
                <div className="trophy showcase-item">
                  <img src={trophy} alt="icon mobile" />
                  <h2>Designed to be easy</h2>
                  <p>
                    Simple plans tailored for home and small business. Sign up
                    online in minutes.
                  </p>
                </div>
              ),
              3: (
                <div className="trophy showcase-item">
                  <img src={network} alt="icon network" />
                  <h2>
                    High-speed <br />
                    network
                  </h2>
                  <p>
                    Get high speed data and call connections with great coverage
                    across 97.2% of Australia's population. Powered by the Optus
                    4G network.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
        <div className="col-md-3">
          {
            {
              1: (
                <div className="speed showcase-item">
                  <img src={rocket} alt="icon rocket" />
                  <h2>No limits</h2>
                  <p>
                    Unleash your online potential with unlimited data usage at
                    no additional cost and without any peak time limits.
                  </p>
                </div>
              ),
              2: (
                <div className="speed showcase-item">
                  <img src={network} alt="icon sim card" />
                  <h2>High speed network</h2>
                  <p>
                    Keep connected to what matters most with our fast, reliable
                    network.
                  </p>
                </div>
              ),
              3: (
                <div className="speed showcase-item">
                  <img src={noLockInContract} alt="icon no lock in contract" />
                  <h2>
                    No lock-in <br />
                    contract
                  </h2>
                  <p>
                    All our mobile plans are month-to-month so you don't have to
                    worry about complex contracts or hidden fees.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
        <div className="col-md-3">
          {
            {
              1: (
                <div className="support showcase-item">
                  <img src={support} alt="icon support" />
                  <h2>Priority Support</h2>
                  <p>
                    Round-the-clock technical support by a specialist team,
                    available every day of the year, ensuring assistance
                    whenever you need it.
                  </p>
                </div>
              ),
              2: (
                <div className="support showcase-item">
                  <img src={support} alt="icon support" />
                  <h2>24/7 technical support</h2>
                  <p>
                    We’re available 24 hours a day, 7 days a week in case
                    something goes wrong.
                  </p>
                </div>
              ),
              3: (
                <div className="support showcase-item">
                  <img
                    src={transferYourExistingNumber}
                    alt="icon transfer your existing number"
                  />
                  <h2>
                    Transfer your <br />
                    Existing number
                  </h2>
                  <p>
                    It's easy to bring your existing mobile number over from
                    your current telco provider or get a new one.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
        <div className="col-md-3">
          {
            {
              1: (
                <div className="support showcase-item">
                  <img
                    src={danger}
                    alt="icon danger"
                    style={{ height: '9rem', marginBottom: '3rem' }}
                  />
                  <h2>Business critical data needs</h2>
                  <p>
                    Experience uninterrupted data excellence with our eSLA
                    (enhanced-12 Service Level Agreement) when bundled with your
                    package, ensuring top-notch support and enhanced fault
                    rectification for your mission-critical business needs.
                  </p>
                </div>
              ),
              2: (
                <div className="support showcase-item">
                  <img src={support} alt="icon support" />
                  <h2>24/7 technical support</h2>
                  <p>
                    We’re available 24 hours a day, 7 days a week in case
                    something goes wrong.
                  </p>
                </div>
              ),
              3: (
                <div className="support showcase-item">
                  <img
                    src={transferYourExistingNumber}
                    alt="icon transfer your existing number"
                  />
                  <h2>
                    Transfer your <br />
                    Existing number
                  </h2>
                  <p>
                    It's easy to bring your existing mobile number over from
                    your current telco provider or get a new one.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
      </div>
    </div>
  </section>
)

Showcase.propTypes = {
  variation: PropTypes.number,
}
